import React, { SyntheticEvent, useState } from 'react'
import ResultViewer from '../../utils/ResultViewer'
import LaneEditModal from './LaneEditModal'
import Swal from 'sweetalert2'
import axios from 'axios'

function LanesList() {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  let usersHeader = [
    {
      index: 1,
      type: 'field',
      key: 'id',
      title: 'ID',
      className: 'min-w-125px',
    },
    { index: 2, type: 'field', key: 'title', title: 'Name', className: 'min-w-125px' },
    {
      index: 3,
      type: 'object',
      key: 'branch',
      subKey: 'title',
      title: 'Branch',
      className: 'min-w-125px',
    },
    { index: 4, type: 'field', key: 'actions', title: 'Actions', className: 'min-w-125px' },
  ]

  return (
    <>
      <ResultViewer
        title={'Lanes'}
        dataUrl={'/admin/lanes'}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          if (id !== undefined && id !== '') {
            setItemIdForUpdate(id)
          }
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this lane?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete('admin/lanes/' + id).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[
          {
            text: 'Add New Lane',
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-grip-lines',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForUpdate !== undefined) {
                setItemIdForUpdate('add')
              }
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        header={usersHeader}
      />
      {itemIdForUpdate !== '' ? (
        <LaneEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onClose={() => {
            setItemIdForUpdate('')
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default LanesList
