import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'

interface Props {
  defaultValue: string
  handleChange: (user: string) => void
  getUsersOptionsForTrainers: (input: string) => void
  userOptions: any[]
}

const AutocompleteUsersWidget: React.FC<Props> = ({
  defaultValue,
  getUsersOptionsForTrainers,
  handleChange,
  userOptions,
}) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue || '')
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (inputValue.trim() !== '') {
        getUsersOptionsForTrainers(inputValue)
      }
    }, 300)

    return () => clearTimeout(timeoutId)
  }, [inputValue])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value
    setInputValue(input)
  }

  const handleOptionSelect = (value: string) => {
    const selectedUser = userOptions.find((user) => user.value === value)
    if (selectedUser) {
      handleChange(selectedUser.value)
    }
  }

  return (
    <Form.Group controlId='formUserAutocomplete'>
      <Form.Label>User</Form.Label>
      <div style={{ position: 'relative' }}>
        {/* Input alanı */}
        <Form.Control
          type='text'
          name='user'
          value={inputValue || defaultValue}
          onChange={handleInputChange}
          autoComplete='off'
          placeholder='Search for a user...'
        />
        {/* Dropdown */}
        {userOptions.length > 0 && (
          <ul
            style={{
              position: 'absolute',
              zIndex: 1000,
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              width: '100%',
              maxHeight: '150px',
              overflowY: 'auto',
              listStyle: 'none',
              padding: 0,
              margin: 0,
            }}
          >
            {userOptions.map((option) => (
              <li
                key={option.value}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #ddd',
                }}
                onClick={() => {
                  handleOptionSelect(option.value)
                  setInputValue(option.label)
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </Form.Group>
  )
}

export default AutocompleteUsersWidget
