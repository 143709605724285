import React, { SyntheticEvent, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import AreaTypeEditModal from './AreaTypeEditModal'
import ResultViewer from '../../../utils/ResultViewer'

function AreaTypeList() {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  let areaTypeHeader = [
    { index: 1, type: 'field', key: 'id', title: 'ID', className: 'min-w-125px' },
    { index: 2, type: 'field', key: 'name', title: 'Name', className: 'min-w-125px' },
    { index: 3, type: 'field', key: 'actions', title: 'Actions', className: 'min-w-125px' },
  ]

  const filters = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      options: null,
      placeholder: 'Enter name',
    },
  ]

  return (
    <>
      <ResultViewer
        title={'Area Types'}
        filter={filters}
        dataUrl={'/admin/area_types'}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          if (id !== undefined && id !== '') {
            setItemIdForUpdate(id)
          }
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this area type?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete('admin/area_types/' + id).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[
          {
            text: 'Add New Area Type',
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-golf-ball',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForUpdate !== undefined) {
                setItemIdForUpdate('add')
              }
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        header={areaTypeHeader}
      />
      {itemIdForUpdate !== '' ? (
        <AreaTypeEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onClose={() => {
            setItemIdForUpdate('')
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default AreaTypeList
