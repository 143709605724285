import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { hoursOptions, reservationTimeIntervalOptions } from '../../utils/Constants'

interface BranchEditModalProps {
  itemIdForUpdate: string
  show: boolean
  onClose: () => void
  refresh: Function
}

class Branch {
  title?: string
  availableTimeStart?: string
  availableTimeEnd?: string
  reservationTimeInterval?: number
}

const BranchEditModal: React.FC<BranchEditModalProps> = ({
  itemIdForUpdate,
  show,
  onClose,
  refresh,
}) => {
  const [formData, setFormData] = useState<Branch>({
    title: '',
    availableTimeStart: '',
    availableTimeEnd: '',
    reservationTimeInterval: 30,
  })

  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'add') {
      axios
        .get(`/admin/branches/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        })
        .then((response) => {
          const { availableTimeStart, availableTimeEnd } = response.data

          const startDate = new Date(availableTimeStart)
          const startTime = `${String(startDate.getUTCHours()).padStart(2, '0')}:${String(
            startDate.getUTCMinutes()
          ).padStart(2, '0')}`

          const endDate = new Date(availableTimeEnd)
          const endTime = `${String(endDate.getUTCHours()).padStart(2, '0')}:${String(
            endDate.getUTCMinutes()
          ).padStart(2, '0')}`

          setFormData({
            title: response.data.title,
            availableTimeStart: startTime,
            availableTimeEnd: endTime,
            reservationTimeInterval: response.data.reservationTimeInterval,
          })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        title: '',
        availableTimeStart: '',
        availableTimeEnd: '',
        reservationTimeInterval: 30,
      })
    }
  }

  const updateData = async () => {
    const currentDate = new Date().toISOString().split('T')[0]

    const startTime = new Date(`${currentDate}T${formData.availableTimeStart}:00Z`).toISOString()
    const endTime = new Date(`${currentDate}T${formData.availableTimeEnd}:00Z`).toISOString()

    axios
      .patch(
        `/admin/branches/${itemIdForUpdate}`,
        { ...formData, availableTimeStart: startTime, availableTimeEnd: endTime },
        {
          headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/merge-patch+json',
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        }
      )
      .then((response) => {
        onClose()
        refresh(true)
      })
      .catch((error) => {
        console.error('Error updating data:', error)
      })
  }

  const createData = async () => {
    const currentDate = new Date().toISOString().split('T')[0]

    const startTime = new Date(`${currentDate}T${formData.availableTimeStart}:00Z`).toISOString()
    const endTime = new Date(`${currentDate}T${formData.availableTimeEnd}:00Z`).toISOString()

    axios
      .post(
        `/admin/branches`,
        { ...formData, availableTimeStart: startTime, availableTimeEnd: endTime },
        {
          headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/ld+json',
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        }
      )
      .then((response) => {
        onClose()
        refresh(true)
        setFormData({
          title: '',
          availableTimeStart: '',
          availableTimeEnd: '',
          reservationTimeInterval: 30,
        })
      })
      .catch((error) => {
        console.error('Error creating data:', error)
      })
  }

  useEffect(() => {
    fetchSingleData()
  }, [itemIdForUpdate])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'reservationTimeInterval' && value != '' ? parseInt(value, 10) : value,
    }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'add') {
      createData()
    } else {
      updateData()
    }
  }

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{itemIdForUpdate === 'add' ? 'Add Branch' : 'Edit Branch'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='formUserTitle'>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='text'
              name='title'
              value={formData.title}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formAvailableTimeStart'>
            <Form.Label>Available Time Start</Form.Label>
            <Form.Select
              name='availableTimeStart'
              value={formData.availableTimeStart}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setFormData((prevData) => ({
                  ...prevData,
                  availableTimeStart: e.target.value,
                }))
              }}
              required
            >
              <option value=''>Select Start Time</option>
              {hoursOptions.map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formAvailableTimeEnd'>
            <Form.Label>Available Time End</Form.Label>
            <Form.Select
              name='availableTimeEnd'
              value={formData.availableTimeEnd}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setFormData((prevData) => ({
                  ...prevData,
                  availableTimeEnd: e.target.value,
                }))
              }}
              required
            >
              <option value=''>Select End Time</option>
              {hoursOptions.map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formReservationTimeInterval'>
            <Form.Label>Max Reservation Time (Minute)</Form.Label>
            <Form.Select
              name='reservationTimeInterval'
              value={formData.reservationTimeInterval}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setFormData((prevData) => ({
                  ...prevData,
                  reservationTimeInterval: parseInt(e.target.value, 10),
                }))
              }}
              required
            >
              <option value=''>Select Max Reservation Time</option>
              {reservationTimeIntervalOptions.map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <div className='text-end mt-3'>
            <Button variant='secondary' onClick={onClose} className='me-2'>
              Cancel
            </Button>
            <Button type='submit' variant='primary'>
              Save changes
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default BranchEditModal
