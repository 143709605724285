import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Accordion } from 'react-bootstrap'
import { userRoles, userStatus } from '../../utils/ConstVariables'
import Swal from 'sweetalert2'

type UserEditModalProps = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: () => void
}

class User {
  name?: string
  surname?: string
  email?: string
  roles: Array<string> = []
  status?: string
  address?: {
    address?: string
    town?: string
    postalCode?: string
    phone?: string
  } = {}
}

const UserEditModal: React.FC<UserEditModalProps> = ({
  onHide,
  show,
  itemIdForUpdate,
  refreshFunction,
}) => {
  const [formData, setFormData] = useState<User>({
    name: '',
    surname: '',
    email: '',
    status: '',
    roles: [],
    address: {},
  })

  const [originalData, setOriginalData] = useState<User | null>(null)

  const roles = userRoles
  const statusOptions = userStatus

  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'new') {
      axios
        .get(`/admin/users/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        })
        .then((response) => {
          const userData = response.data

          const addressData = userData.address || {}
          const formattedData = {
            name: userData.name,
            surname: userData.surname,
            email: userData.email,
            status: userData.status,
            roles: userData.roles,
            address: {
              address: addressData.address || '',
              town: addressData.town || '',
              postalCode: addressData.postalCode || '',
              phone: addressData.phone || '',
            },
          }

          setFormData(formattedData)
          setOriginalData(formattedData)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        name: '',
        surname: '',
        email: '',
        status: '',
        roles: [],
        address: {},
      })
      setOriginalData(null)
    }
  }

  const getChangedFields = (original: any, updated: any) => {
    let changes: any = {}
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        changes[key] = updated[key]
      }
    }
    return changes
  }

  const updateData = async () => {
    if (!originalData) return

    const changes = getChangedFields(originalData, formData)

    if (Object.keys(changes).length === 0) {
      console.log('No changes detected')
      return
    }

    axios
      .patch(`/admin/users/${itemIdForUpdate}`, changes, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onHide()
        refreshFunction && refreshFunction()
      })
      .catch((error) => {
        console.error('Error updating data:', error)
      })
  }

  const createData = async () => {
    axios
      .post(`/admin/users`, formData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onHide()
        refreshFunction && refreshFunction()
        setFormData({
          name: '',
          surname: '',
          email: '',
          status: '',
          roles: [],
          address: {},
        })
      })
      .catch((error) => {
        console.error('Error creating data:', error)
      })
  }

  useEffect(() => {
    if (itemIdForUpdate !== 'new') {
      fetchSingleData()
    }
  }, [itemIdForUpdate])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleMultipleCheckSelectChange = (event: any) => {
    const { value, checked } = event.target
    const newRoles = checked
      ? [...formData.roles, value]
      : formData.roles.filter((role: any) => role !== value)
    setFormData({ ...formData, roles: newRoles })
  }

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        [name]: value,
      },
    }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'new') {
      createData()
    } else if (itemIdForUpdate !== 'new' && itemIdForUpdate !== '') {
      updateData()
    }
  }

  const [showPasswordModal, setShowPasswordModal] = useState(false)

  const handlePasswordChange = (newPassword: string, confirmPassword: string) => {
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Passwords do not match',
        position: 'bottom',
        width: '30rem',
        heightAuto: true,
      })
      return
    }

    axios
      .put(
        `/admin/users/change-password/${itemIdForUpdate}`,
        { password: newPassword },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        }
      )
      .then(() => {
        setShowPasswordModal(false)
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Password changed successfully',
        })
      })
      .catch((error) => {
        console.error('Error changing password:', error)
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to change password',
          position: 'bottom',
          width: '30rem',
          heightAuto: true,
        })
      })
  }
  return (
    <div>
      <Modal show={show} onHide={onHide} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>{itemIdForUpdate === 'new' ? 'Add User' : 'Edit User'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='formUserName'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                name='name'
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId='formUserSurname'>
              <Form.Label>Surname</Form.Label>
              <Form.Control
                type='text'
                name='surname'
                value={formData.surname}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId='formUserEmail'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='formUserRoles'>
              <Form.Label>Roles</Form.Label>
              {roles.map((role) => (
                <Form.Check
                  type='checkbox'
                  key={role.value}
                  label={role.label}
                  value={role.value}
                  checked={Object.values(formData.roles)?.includes(role.value)}
                  onChange={handleMultipleCheckSelectChange}
                  style={{ marginBottom: '10px' }}
                />
              ))}
            </Form.Group>

            {/* Address Fields */}
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Address</Accordion.Header>
                <Accordion.Body>
                  <Form.Group controlId='formUserAddress' className='mb-3'>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type='text'
                      name='address'
                      value={formData.address?.address || ''}
                      onChange={handleAddressChange}
                      required
                    />
                  </Form.Group>

                  <div className='row mb-3'>
                    <div className='col-md-6'>
                      <Form.Group controlId='formUserTown'>
                        <Form.Label>Town</Form.Label>
                        <Form.Control
                          type='text'
                          name='town'
                          value={formData.address?.town || ''}
                          onChange={handleAddressChange}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className='col-md-6'>
                      <Form.Group controlId='formPostalCode'>
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          type='text'
                          name='postalCode'
                          value={formData.address?.postalCode || ''}
                          onChange={handleAddressChange}
                          required
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group controlId='formPhone' className='mb-3'>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type='text'
                      name='phone'
                      value={formData.address?.phone || ''}
                      onChange={handleAddressChange}
                      required
                    />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Form.Group controlId='formUserStatus'>
              <Form.Label>Status</Form.Label>
              <Form.Select
                name='status'
                value={formData.status}
                onChange={handleSelectChange}
                required
                style={{
                  backgroundColor:
                    formData.status === 'Active'
                      ? '#004f44'
                      : formData.status === 'Banned'
                      ? '#dc3545'
                      : formData.status === 'Blocked'
                      ? '#ffc107'
                      : '#6c757d',
                  color: 'white',
                  border: 'none',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                <option value=''>Select status</option>
                {statusOptions.map((status) => (
                  <option
                    key={status.value}
                    value={status.value}
                    style={{ backgroundColor: '#f8f9fa', color: '#343a40' }}
                  >
                    {status.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Modal.Footer>
              <Button variant='warning' onClick={() => setShowPasswordModal(true)}>
                Change Password
              </Button>
              <Button variant='secondary' onClick={onHide} className='me-2'>
                Cancel
              </Button>
              <Button type='submit' variant='primary'>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <ChangePasswordModal
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
        onSubmit={handlePasswordChange}
      />
    </div>
  )
}

const ChangePasswordModal: React.FC<{
  show: boolean
  onHide: () => void
  onSubmit: (newPassword: string, confirmPassword: string) => void
}> = ({ show, onHide, onSubmit }) => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(newPassword, confirmPassword)
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      style={{
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        zIndex: 9999,
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handlePasswordSubmit}>
          <Form.Group controlId='formNewPassword'>
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter new password'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              isInvalid={newPassword !== confirmPassword && confirmPassword.length > 0}
            />
            <Form.Control.Feedback type='invalid'>Passwords do not match</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='formConfirmPassword' className='mt-3'>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Confirm new password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              isInvalid={newPassword !== confirmPassword && confirmPassword.length > 0}
            />
            <Form.Control.Feedback type='invalid'>Passwords do not match</Form.Control.Feedback>
          </Form.Group>
          <div className='text-end mt-3'>
            <Button variant='secondary' onClick={onHide} className='me-2'>
              Cancel
            </Button>
            <Button type='submit' variant='primary' disabled={newPassword !== confirmPassword}>
              Change Password
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default UserEditModal
