import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { fetchTrainers } from '../../../utils/SelectListMethods'
import { hoursOptions } from '../../../utils/Constants'
import { useAuth } from '../../auth/core/AuthProvider'

interface TrainerAvailabilityProps {
  itemIdForUpdate: string
  show: boolean
  onClose: () => void
  refresh: Function
}

class TrainerAvailability {
  user?: string
  startsAt?: string
  endsAt?: string
  days?: string[]
  startTime?: string
  endTime?: string
}

const TrainerAvailabilityEditModal: React.FC<TrainerAvailabilityProps> = ({
  itemIdForUpdate,
  show,
  onClose,
  refresh,
}) => {
  const [formData, setFormData] = useState<TrainerAvailability>({
    user: '',
    startsAt: '',
    endsAt: '',
    days: [],
    startTime: '',
    endTime: '',
  })

  const [trainerOptions, setTrainersOptions] = useState<any[]>([])
  const [daysSelected, setDaysSelected] = useState<string[]>([])
  const [allSelected, setAllSelected] = useState(false)
  const { hasValidRole, token } = useAuth()
  let apiUrl = 'trainer/availability_calendars'
  if (hasValidRole === 1) {
    apiUrl = 'admin/availability_calendars'
  }

  useEffect(() => {
    fetchTrainers(token ?? '').then((response) => {
      setTrainersOptions(response)
    })
  }, [])

  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'add') {
      axios
        .get(`${apiUrl}/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        })
        .then((response) => {
          setFormData({
            startsAt: new Date(response.data.startsAt).toISOString().substring(0, 10),
            endsAt: new Date(response.data.endsAt).toISOString().substring(0, 10),
            days: response.data.days,
            startTime: new Date(response.data.startTime).toISOString().substring(11, 16),
            endTime: new Date(response.data.endTime).toISOString().substring(11, 16),
          })
          setDaysSelected(response.data.days || [])
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        user: '',
        startsAt: '',
        endsAt: '',
        days: [],
        startTime: '',
        endTime: '',
      })
    }
  }

  const updateData = async () => {
    const currentDate = new Date().toISOString().split('T')[0]

    const editedStartTime = new Date(`${currentDate}T${formData.startTime}:00Z`).toISOString()
    const editedEndTime = new Date(`${currentDate}T${formData.endTime}:00Z`).toISOString()

    const updatedData = {
      ...formData,
      days: daysSelected,
      startTime: editedStartTime,
      endTime: editedEndTime,
    }
    axios
      .patch(`${apiUrl}/${itemIdForUpdate}`, updatedData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
      })
      .catch((error) => {
        console.error('Error updating data:', error)
      })
  }

  const createData = async () => {
    const currentDate = new Date().toISOString().split('T')[0]

    const editedStartTime = new Date(`${currentDate}T${formData.startTime}:00Z`).toISOString()
    const editedEndTime = new Date(`${currentDate}T${formData.endTime}:00Z`).toISOString()
    const newData = {
      ...formData,
      days: daysSelected,
      startTime: editedStartTime,
      endTime: editedEndTime,
    }
    axios
      .post(`/${apiUrl}`, newData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
        setFormData({
          startsAt: '',
          endsAt: '',
          days: [],
          startTime: '',
          endTime: '',
        })
      })
      .catch((error) => {
        console.error('Error creating data:', error)
      })
  }

  useEffect(() => {
    if (itemIdForUpdate !== 'add') {
      fetchSingleData()
    }
  }, [itemIdForUpdate])

  useEffect(() => {
    setAllSelected(daysSelected.length === 7)
  }, [daysSelected])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleDayClick = (index: string) => {
    setDaysSelected((prevDays) =>
      prevDays.includes(index) ? prevDays.filter((day) => day !== index) : [...prevDays, index]
    )
  }

  const handleAllClick = () => {
    if (allSelected) {
      setDaysSelected([])
    } else {
      setDaysSelected(['1', '2', '3', '4', '5', '6', '7'])
    }
    setAllSelected(!allSelected)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'add') {
      createData()
    } else {
      updateData()
    }
  }

  const dayPairs = [
    { label: 'Monday', value: '1' },
    { label: 'Tuesday', value: '2' },
    { label: 'Wednesday', value: '3' },
    { label: 'Thursday', value: '4' },
    { label: 'Friday', value: '5' },
    { label: 'Saturday', value: '6' },
    { label: 'Sunday', value: '7' },
  ]

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>
          {itemIdForUpdate === 'add' ? 'Add Availability' : 'Edit Availability'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {hasValidRole === 1 && (
            <Form.Group controlId='formTrainer'>
              <Form.Label>Trainer</Form.Label>
              <Form.Select name='user' value={formData.user} onChange={handleChange} required>
                {trainerOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          <Form.Group controlId='formStartsAt'>
            <Form.Label>Starts At</Form.Label>
            <Form.Control
              type='date'
              name='startsAt'
              value={formData.startsAt}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formEndsAt'>
            <Form.Label>Ends At</Form.Label>
            <Form.Control
              type='date'
              name='endsAt'
              value={formData.endsAt}
              onChange={handleChange}
              required
            />
          </Form.Group>

          {/* Days of Week Buttons */}
          <Form.Group controlId='formDays'>
            <Form.Label>Select Days</Form.Label>
            <div className='mb-3'>
              <Button
                variant={allSelected ? 'danger' : 'outline-primary'}
                onClick={handleAllClick}
                className='me-2 mb-2'
              >
                {allSelected ? 'Clear All' : 'All'}
              </Button>
              {dayPairs.map((day) => (
                <Button
                  key={day.value}
                  variant={daysSelected.includes(day.value) ? 'primary' : 'outline-primary'}
                  onClick={() => handleDayClick(day.value)}
                  className='me-2 mb-2'
                >
                  {day.label}
                </Button>
              ))}
            </div>
          </Form.Group>

          <Form.Group controlId='formStartTime'>
            <Form.Label>Start Time</Form.Label>
            <Form.Select
              name='startTime'
              value={formData.startTime}
              onChange={handleChange}
              required
            >
              {hoursOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formEndTime'>
            <Form.Label>End Time</Form.Label>
            <Form.Select name='endTime' value={formData.endTime} onChange={handleChange} required>
              {hoursOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
        <Button variant='primary' type='submit' onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TrainerAvailabilityEditModal
