import React, { SyntheticEvent, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import ResultViewer from '../../../utils/ResultViewer'
import MembershipRequestEditModal from './MembershipRequestEditModal'
import { useLocation } from 'react-router-dom'

function MembershipRequestList() {
  const location = useLocation()
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  useEffect(() => {
    if (location.pathname.includes('/membership/list')) {
      const pathParts = location.pathname.split('/')
      const id = pathParts[pathParts.length - 1]
      if (!isNaN(Number(id))) {
        setItemIdForUpdate(id)
      }
    } else {
      setItemIdForUpdate('')
    }
  }, [location])
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})

  const filters = [
    {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: { Approved: 'Approved', Pending: 'Pending', Canceled: 'Canceled' },
    },
  ]
  let membershipPackageRequestHeader = [
    {
      index: 1,
      type: 'field',
      key: 'id',
      title: 'ID',
      className: 'min-w-125px',
      sort: true,
    },
    {
      index: 2,
      type: 'object',
      key: 'user',
      subKey: 'name',
      title: 'User',
      className: 'min-w-125px',
    },
    {
      index: 3,
      type: 'object',
      key: 'user',
      subKey: 'email',
      title: 'Email',
      className: 'min-w-125px',
    },
    {
      index: 4,
      type: 'object',
      key: 'membershipPackage',
      subKey: 'name',
      title: 'Membership',
      className: 'min-w-125px',
    },
    {
      index: 5,
      type: 'field',
      key: 'status',
      title: 'Status',
      className: 'min-w-125px',
      postProcess: (status: string) => {
        return status === 'Pending' ? (
          <span className='badge badge-warning'>Pending</span>
        ) : status === 'Approved' ? (
          <span className='badge badge-success'>Approved</span>
        ) : (
          <span className='badge badge-danger'>Canceled</span>
        )
      },
    },
    { index: 6, type: 'field', key: 'actions', title: 'Actions', className: 'min-w-125px' },
  ]
  return (
    <>
      <ResultViewer
        title={'Membership Requests'}
        filter={filters}
        dataUrl={'/admin/membership_package_requests'}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          if (id !== undefined && id !== '') {
            setItemIdForUpdate(id)
          }
        }}
        actionItems={[]}
        header={membershipPackageRequestHeader}
      />
      {itemIdForUpdate !== '' ? (
        <MembershipRequestEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onClose={() => {
            setItemIdForUpdate('')
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default MembershipRequestList
