import React, { Component } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import axios from 'axios'
import { Pagination } from 'react-bootstrap'
import Filters from './ResultViewer/Filters'

interface Props<T> {
  title: string
  dataUrl: string
  editCallback: Function
  deleteCallback?: Function
  actionItems?: Array<any>
  header: Array<{
    index: number
    type: string
    subKey?: string
    title: string
    className: string
    key: keyof T
    postProcess?: Function
    sort?: boolean
  }>
  filter?: Array<any>
  itemsPerPage?: number
}

interface State<T> {
  data: T[]
  loading: boolean
  error: string | null
  page: number
  totalCount: number
  sortKey: keyof T | null
  sortOrder: 'asc' | 'desc'
  filters: Record<string, string>
}

class ResultViewer<T extends Record<string, any>> extends Component<Props<T>, State<T>> {
  static defaultProps = {
    itemsPerPage: 10,
  }

  token: string | null
  constructor(props: Props<T>) {
    super(props)
    this.state = {
      data: [],
      loading: true,
      error: null,
      page: 1,
      totalCount: 0,
      sortKey: 'id',
      sortOrder: 'desc',
      filters: {},
    }
    this.token = localStorage.getItem('@auth/token')
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps: Props<T>, prevState: State<T>) {
    if (
      prevState.page !== this.state.page ||
      prevProps.dataUrl !== this.props.dataUrl ||
      prevState.filters !== this.state.filters
    ) {
      this.fetchData()
    }
  }

  sortData = (data: T[]): T[] => {
    const { sortKey, sortOrder } = this.state

    if (!sortKey) return data

    return data.sort((a, b) => {
      const aValue = a[sortKey]
      const bValue = b[sortKey]

      if (sortKey === 'id') {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue
      }
      if (sortOrder === 'asc') {
        return aValue > bValue ? 1 : -1
      } else {
        return aValue < bValue ? 1 : -1
      }
    })
  }

  handleFilterChange = (key: string, value: string) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [key]: value,
      },
      page: 1, //resetting page
    }))
  }

  fetchData = async () => {
    const { page, filters } = this.state
    const { dataUrl } = this.props
    this.setState({ loading: true })
    try {
      const filterQuery = Object.entries(filters)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      const res = await axios
        .get(
          `${dataUrl}?page=${page}&pageSize=${
            this.props.itemsPerPage
          }&${filterQuery}&order[${String(this.state.sortKey)}]=${this.state.sortOrder}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            data: res.data['hydra:member'],
            totalCount: res.data['hydra:totalItems'],
            loading: false,
          })
        })
    } catch (error) {
      console.error('Error fetching data:', error)
      this.setState({ error: 'Error fetching data', loading: false })
    }
  }

  handlePageChange = (newPage: number) => {
    this.setState({ page: newPage })
  }

  handleSort = (key: keyof T) => {
    const noSortKeys = ['status', 'actions']

    if (noSortKeys.includes(key as string)) return
    const headerItem = this.props.header.find((item) => item.key === key)

    if (!headerItem || !headerItem.sort) return
    this.setState(
      (prevState) => ({
        sortKey: key,
        sortOrder: prevState.sortKey === key && prevState.sortOrder === 'asc' ? 'desc' : 'asc',
      }),
      this.fetchData
    )
  }
  renderPagination = () => {
    const { page, totalCount } = this.state
    const totalPages = Math.ceil(totalCount / this.props.itemsPerPage!)
    const paginationItems = []

    for (let number = 1; number <= totalPages; number++) {
      paginationItems.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={() => this.handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      )
    }

    return (
      <Pagination>
        <Pagination.Prev
          onClick={() => page > 1 && this.handlePageChange(page - 1)}
          disabled={page === 1}
        />
        {paginationItems}
        <Pagination.Next
          onClick={() => page < totalPages && this.handlePageChange(page + 1)}
          disabled={page === totalPages}
        />
      </Pagination>
    )
  }

  render() {
    const { title, editCallback, deleteCallback, actionItems, header, filter } = this.props
    const { data, loading, error, totalCount } = this.state

    return (
      <div className={`card`}>
        {/* Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{totalCount} results found.</span>
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
            >
              {actionItems?.map((item, index) => (
                <button
                  id='#action_button'
                  key={index}
                  className={item.class}
                  style={{ color: 'white', backgroundColor: '#004F44' }}
                  onClick={() => editCallback('add', this.fetchData)}
                >
                  <i className={item.icon} style={{ marginRight: '5px' }}></i>
                  {item.text}
                </button>
              ))}
              {/* Filters */}
              {filter && filter.length > 0 && (
                <div className='card-body'>
                  <Filters
                    search={filter || []}
                    setFilterParams={this.handleFilterChange}
                    applyFilter={this.fetchData}
                    clearFilters={() => this.setState({ filters: {} })}
                  />
                </div>
              )}
            </div>
            {/* end::Menu */}
          </div>
        </div>

        {/* Body */}
        <div className='card-body py-3'>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : data && data.length > 0 ? (
            // begin::Table container
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  {header && header.length > 0 ? (
                    <tr className='fw-bold text-muted bg-light'>
                      {header.map((item, index) => (
                        <th key={index} className={item.className}>
                          <div
                            onClick={() => this.handleSort(item.key)}
                            className={item.sort ? 'sortable-header' : ''}
                            style={{
                              cursor: item.sort ? 'pointer' : 'default',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }}
                          >
                            {item.title}
                            {this.state.sortKey === item.key ? (
                              <span>{this.state.sortOrder === 'asc' ? '↑' : '↓'}</span>
                            ) : (
                              item.sort && <span>⇅</span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ) : (
                    <>Header Error</>
                  )}
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data.map((item: any, index: number) => (
                    <tr key={index}>
                      {header && header.length > 0 ? (
                        header.map((headerItem, headerIndex) => (
                          <td key={headerIndex}>
                            {headerItem.key === 'actions' ? (
                              <div>
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => editCallback(item?.id, this.fetchData)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='svg-icon-3'
                                  />
                                </a>
                                {deleteCallback && (
                                  <a
                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                    onClick={() => deleteCallback(item?.id, this.fetchData)}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3'
                                    />
                                  </a>
                                )}
                              </div>
                            ) : headerItem.type === 'object' && headerItem.subKey ? (
                              headerItem.postProcess ? (
                                headerItem.postProcess(
                                  item?.[headerItem.key]?.[headerItem.subKey] || ''
                                )
                              ) : (
                                item?.[headerItem.key]?.[headerItem.subKey] || 'N/A'
                              )
                            ) : headerItem.postProcess ? (
                              headerItem.postProcess(item?.[headerItem.key] || '')
                            ) : (
                              item?.[headerItem.key] || 'N/A'
                            )}
                          </td>
                        ))
                      ) : (
                        <>Header Error</>
                      )}
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          ) : (
            // end::Table container
            <p>No data available.</p>
          )}
        </div>
        {/* end::Body */}
        {/* begin::Footer */}
        <div className='card-footer d-flex justify-content-between align-items-center'>
          {this.renderPagination()}
        </div>
        {/* end::Footer */}
      </div>
    )
  }
}

export default ResultViewer
