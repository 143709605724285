import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import '../otp.css'
type OTPModalProps = {
  show: boolean
  resendStatus: boolean
  handleClose: () => void
  handleSubmit: (otpCode: string) => void
  handleResend: () => void
  countdownSeconds?: number
}

const OTPModal = ({
  show,
  resendStatus,
  handleClose,
  handleSubmit,
  handleResend,
  countdownSeconds = 300,
}: OTPModalProps) => {
  const [otpCode, setOtpCode] = useState('')
  const [secondsLeft, setSecondsLeft] = useState(countdownSeconds)
  const [resendEnabled, setResendEnabled] = useState(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpCode(e.target.value)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit(otpCode)
  }

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined
    if (show) {
      setSecondsLeft(countdownSeconds)
      setResendEnabled(false)
      timer = setInterval(() => {
        setSecondsLeft((prevSeconds) => {
          if (prevSeconds <= 1) {
            clearInterval(timer!)
            setResendEnabled(true)
            return 0
          }
          return prevSeconds - 1
        })
      }, 1000)
    } else {
      clearInterval(timer!)
    }

    return () => clearInterval(timer!)
  }, [show, countdownSeconds, handleClose, resendStatus])

  const handleResendCode = () => {
    handleResend()
    setSecondsLeft(countdownSeconds)
    setResendEnabled(false)
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' className='otp-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title>Enter OTP Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group controlId='formOtpCode'>
            <Form.Label>OTP Code</Form.Label>
            <Form.Control
              type='text'
              value={resendEnabled ? '' : otpCode}
              onChange={handleChange}
              readOnly={resendEnabled}
              required
            />
          </Form.Group>
          {secondsLeft > 0 && resendEnabled == false && (
            <Button variant='primary' type='submit' className='btn-primary'>
              Verify
            </Button>
          )}

          {resendEnabled && (
            <Button variant='primary' onClick={handleResendCode} className='btn-primary'>
              Resend Code
            </Button>
          )}
          <div className='mt-3 text-danger'>
            {secondsLeft > 0 && `${secondsLeft} seconds left.`}
            {secondsLeft == 0 && 'Time is up. Resend code.'}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default OTPModal
