import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { EventInput } from '@fullcalendar/core'
import enLocale from '@fullcalendar/core/locales/en-gb'
import axios from 'axios'
import '../booking_calendar.scss'
import {
  fetchAreaIdsSelectList,
  fetchAreasList,
  fetchTrainersList,
  fetchTrainersListByRole,
} from '../../../utils/SelectListMethods'
import { useAuth } from '../../auth/core/AuthProvider'
import AddBookingModal from './AddBookingModal'
interface Slot {
  start: string
  end: string
}

interface DateSlots {
  [time: string]: Slot
}

interface AvailabilityCombineResponse {
  [date: string]: DateSlots
}
interface AreaAvailabilityResponse {
  [areaId: string]: {
    area: {
      id: number
      title: string
      areaType: {
        id: number
        name: string
      }
      branch: {
        id: number
        title: string
        availableTimeStart: string
        availableTimeEnd: string
        reservationTimeInterval: number
      }
      availableTimeStart: string
      availableTimeEnd: string
      reservationTimeInterval: number
    }
    availability: Array<{
      date: string
      available_slots: {
        [time: string]: {
          start: string
          end: string
        }
      }
    }>
  }
}

const NewBookingCalendar: React.FC = () => {
  const [events, setEvents] = useState<EventInput[]>([])
  const [bookings, setBookings] = useState<EventInput[]>([])
  const [trainerOptions, setTrainerOptions] = useState<any[]>([])
  const [areaOptions, setAreaOptions] = useState<any[]>([])
  const [selectedTrainer, setSelectedTrainer] = useState<string>('')
  const [selectedArea, setSelectedArea] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { token, hasValidRole, userId } = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState<any>(null)
  const calendarRef = useRef<any>(null)
  const prevDatesRef = useRef({ start: '', end: '' })
  const fetchTrainers = async () => {
    fetchTrainersListByRole(token ?? '').then((response) => {
      setTrainerOptions(response)
    })
  }

  const fetchAreas = async () => {
    fetchAreaIdsSelectList(token ?? '').then((response) => {
      setAreaOptions(response)
    })
  }

  const handleModalClose = () => {
    setShowModal(false)
    setSelectedSlot(null)
  }

  useEffect(() => {
    fetchTrainers()
    fetchAreas()
  }, [])

  const fetchAvailability = async (
    trainerId?: string,
    areaId?: string,
    start_date?: string,
    end_date?: string
  ) => {
    setLoading(true)
    setEvents([])
    setBookings([])

    try {
      if (trainerId && !areaId) {
        await fetchTrainerAvailability(trainerId, start_date, end_date)
        await fetchBookings(trainerId, areaId, start_date, end_date)
      } else if (areaId && !trainerId) {
        if (hasValidRole == 2) {
          await fetchCombinedAvailability(userId, areaId, start_date, end_date)
        } else {
          await fetchAreaAvailability(areaId, start_date, end_date)
        }
        await fetchBookings(trainerId, areaId, start_date, end_date)
      } else if (trainerId && areaId) {
        await fetchCombinedAvailability(trainerId, areaId, start_date, end_date)
        await fetchBookings(trainerId, areaId, start_date, end_date)
      } else {
        if (hasValidRole == 2) {
          await fetchTrainerAvailability('trainer', start_date, end_date)
          await fetchBookings(trainerId, areaId, start_date, end_date)
        }
      }
    } catch (error) {
      console.error('Error fetching availability or bookings:', error)
    } finally {
      setLoading(false)
    }
  }

  // Trainer Availability Fetching
  const fetchTrainerAvailability = async (
    trainerId: string,
    start_date?: string,
    end_date?: string
  ) => {
    try {
      if (trainerId === 'trainer') {
        trainerId = userId
      }
      const response = await axios.get(
        `/trainer-availability/${trainerId}?start=${start_date}&end=${end_date}`
      )
      const newEvents = transformTrainerAvailability(response.data[trainerId].availability)
      setEvents(newEvents)
    } catch (error) {
      console.error('Error fetching trainer availability:', error)
    }
  }

  // Area Availability Fetching
  const fetchAreaAvailability = async (areaId: string, start_date?: string, end_date?: string) => {
    try {
      const response = await axios.get(
        `/area-availability/${areaId}?start=${start_date}&end=${end_date}`
      )

      const newEvents = transformAreaAvailability(response.data)
      setEvents(newEvents)
    } catch (error) {
      console.error('Error fetching area availability:', error)
    }
  }

  // Combined Availability Fetching
  const fetchCombinedAvailability = async (
    trainerId: string,
    areaId: string,
    start_date?: string,
    end_date?: string
  ) => {
    try {
      const response = await axios.get(
        `/availability/${areaId}/${trainerId}?start=${start_date}&end=${end_date}`
      )
      const newEvents = transformCombinedAvailability(response.data)
      setEvents(newEvents)
    } catch (error) {
      console.error('Error fetching combined availability:', error)
    }
  }

  // Bookings Fetching
  const fetchBookings = async (
    trainerId?: string,
    areaId?: string,
    start_date?: string,
    end_date?: string
  ) => {
    try {
      if (hasValidRole == 1) {
        const queryParams = new URLSearchParams({
          page: '1',
          pageSize: '10',
          'status[0]': 'Approved',
          'status[1]': 'Pending',
          ...(trainerId && { trainer: trainerId }),
          ...(areaId && { area: areaId }),
          ...(start_date && { 'date[after]': start_date }),
          ...(end_date && { 'date[before]': end_date }),
        }).toString()

        const response = await axios.get(`/admin/bookings?${queryParams}`)
        const bookingEvents = transformBookingData(response.data['hydra:member'] || [])
        setBookings(bookingEvents)
      } else if (hasValidRole == 2) {
        const queryParams = new URLSearchParams({
          page: '1',
          pageSize: '10',
          'status[0]': 'Approved',
          'status[1]': 'Pending',
          ...(areaId && { area: areaId }),
          ...(start_date && { 'date[after]': start_date }),
          ...(end_date && { 'date[before]': end_date }),
        }).toString()

        const response = await axios.get(`/trainer/bookings?${queryParams}`)
        const bookingEvents = transformBookingData(response.data['hydra:member'] || [])

        setBookings(bookingEvents)
      }
    } catch (error) {
      console.error('Error fetching bookings:', error)
    }
  }

  // Utility Functions for Data Transformation
  const transformTrainerAvailability = (availability: Record<string, any>) => {
    const events: EventInput[] = []
    Object.entries(availability).forEach(([date, slots]) => {
      Array.isArray(slots) &&
        slots.forEach((slot: { start: string; end: string }) => {
          events.push({
            start: `${date}T${slot.start}`,
            end: `${date}T${slot.end}`,
            backgroundColor: '#004F44',
            borderColor: '#004F44',
            textColor: '#FFFFFF',
          })
        })
    })
    return events
  }

  const transformAreaAvailability = (data: Record<string, any>) => {
    const events: EventInput[] = []
    Object.values(data).forEach((areaData: any) => {
      Object.values(areaData.availability).forEach((dayData: any) => {
        Object.values(dayData.available_slots).forEach((slot: any) => {
          const dateWithoutTime = dayData.date.split('T')[0]
          events.push({
            start: `${dateWithoutTime}T${slot.start}`,
            end: `${dateWithoutTime}T${slot.end}`,
            backgroundColor: '#004F44',
            borderColor: '#004F44',
            textColor: '#FFFFFF',
          })
        })
      })
    })
    return events
  }

  const transformCombinedAvailability = (data: Record<string, any>) => {
    return Object.entries(data).flatMap(([date, slots]: [string, any]) =>
      Object.values(slots).map((slot: any) => ({
        start: `${date}T${slot.start}:00`,
        end: `${date}T${slot.end}:00`,
        backgroundColor: '#004F44',
        borderColor: '#004F44',
        textColor: '#FFFFFF',
      }))
    )
  }

  const transformBookingData = (bookings: any[]) => {
    return bookings.map((booking) => ({
      title: `${booking.trainer ? 'Trainer' : 'Area'}`,
      start: booking.date.split('T')[0] + 'T' + booking.startTime.split('T')[1],
      end: booking.date.split('T')[0] + 'T' + booking.endTime.split('T')[1],
      backgroundColor: booking.status === 'Pending' ? '#FFA500' : '#4CAF50',
      borderColor: booking.status === 'Pending' ? '#FFA500' : '#4CAF50',
      textColor: '#000000',
      id: booking.id,
      extendedProps: {
        trainer: booking.trainer ? booking.trainer.name : null,
        area: booking.area ? booking.area.title : null,
        user: booking.user ? booking.user.name : null,
      },
    }))
  }

  const handleTrainerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newTrainerId = e.target.value
    setSelectedTrainer(newTrainerId)
    if (e.target.value || selectedArea) {
      if (calendarRef.current) {
        const api = calendarRef.current.getApi()
        const start = api.view.currentStart.toISOString().split('T')[0]
        const end = api.view.currentEnd.toISOString().split('T')[0]

        if (newTrainerId || selectedArea) {
          fetchAvailability(newTrainerId, selectedArea, start, end)
        }
      }
    }
  }

  const handleAreaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newAreaId = e.target.value
    setSelectedArea(newAreaId)
    if (selectedTrainer || e.target.value) {
      if (calendarRef.current) {
        const api = calendarRef.current.getApi()
        const start = api.view.currentStart.toISOString().split('T')[0]
        const end = api.view.currentEnd.toISOString().split('T')[0]

        if (newAreaId || selectedTrainer) {
          fetchAvailability(selectedTrainer, newAreaId, start, end)
        }
      }
    } else {
      if (calendarRef.current) {
        const api = calendarRef.current.getApi()
        const start = api.view.currentStart.toISOString().split('T')[0]
        const end = api.view.currentEnd.toISOString().split('T')[0]

        fetchAvailability(selectedTrainer, newAreaId, start, end)
      }
    }
  }

  const handleEventClick = (info: any) => {
    if (info.event.title == 'Area' || info.event.title == 'Trainer' || selectedArea) {
      const event = info.event
      setSelectedSlot({
        id: event.id,
        start: event.start,
        end: event.end,
        startTime: event.start.toISOString().split('T')[1],
        endTime: event.end.toISOString().split('T')[1],
        date: event.start.toISOString().split('T')[0],
        area: selectedArea,
        trainer: selectedTrainer,
        username: event.extendedProps.user ?? '',
      })
      setShowModal(true)
    }
  }
  const handleDatesSet = (arg: any) => {
    let start = arg.startStr.split('T')[0]
    let end = arg.endStr.split('T')[0]

    if (prevDatesRef.current.start !== start || prevDatesRef.current.end !== end) {
      prevDatesRef.current = { start, end }
      fetchAvailability(selectedTrainer, selectedArea, start, end)
    }
  }

  useEffect(() => {
    if (!showModal) {
      setEvents([])
      setBookings([])
      setTimeout(() => {
        fetchAvailability(
          selectedTrainer,
          selectedArea,
          prevDatesRef.current.start,
          prevDatesRef.current.end
        )
      }, 250)
    }
  }, [showModal])

  let combinedEvents = [...events, ...bookings]
  return (
    <div className='booking-edit-page'>
      <div className='card mb-4'>
        <div className='card-body'>
          <div className='row g-3'>
            {hasValidRole == 1 && (
              <div className='col-md-6'>
                <label className='form-label'>Trainer</label>
                <select
                  className='form-select'
                  value={selectedTrainer}
                  onChange={handleTrainerChange}
                >
                  <option value=''>Select Trainer</option>
                  {trainerOptions.map((trainer) => (
                    <option key={trainer.value} value={trainer.value}>
                      {trainer.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className='col-md-6'>
              <label className='form-label'>Area</label>
              <select className='form-select' value={selectedArea} onChange={handleAreaChange}>
                <option value=''>Select Area</option>
                {areaOptions.map((area) => (
                  <option key={area.value} value={area.value}>
                    {area.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div
        className='legend'
        style={{
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          padding: '10px',
          justifyContent: 'center',
        }}
      >
        <div
          className='legend-item'
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <span
            className='legend-color'
            style={{
              backgroundColor: '#004F44',
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              display: 'inline-block',
            }}
          ></span>
          <span className='legend-label'>Available For Booking</span>
        </div>
        <div
          className='legend-item'
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <span
            className='legend-color'
            style={{
              backgroundColor: '#4CAF50',
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              display: 'inline-block',
            }}
          ></span>
          <span className='legend-label'>Approved Booking</span>
        </div>
        <div
          className='legend-item'
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <span
            className='legend-color'
            style={{
              backgroundColor: '#FFA500',
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              display: 'inline-block',
            }}
          ></span>
          <span className='legend-label'>Pending Booking</span>
        </div>
      </div>
      <div className='card-light'>
        <div className='card-body'>
          <div className='calendar-section'>
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView='timeGridWeek'
              events={Object.values(combinedEvents)}
              height='auto'
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              slotMinTime='09:00:00'
              slotMaxTime='18:00:00'
              allDaySlot={false}
              locale={enLocale}
              eventClick={handleEventClick}
              slotDuration='00:15:00'
              slotLabelInterval='01:00'
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }}
              datesSet={handleDatesSet}
              timeZone='UTC'
              eventContent={(eventInfo) => {
                if (eventInfo.event.title == '') {
                  return (
                    <div>
                      <b>{eventInfo.timeText}</b>
                      <p>Available</p>
                    </div>
                  )
                } else if (eventInfo.event.title === 'Trainer') {
                  return (
                    <div>
                      <b>{eventInfo.timeText}</b>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <span style={{ fontWeight: 'bold' }}>Trainer:</span>
                        <span>{eventInfo.event.extendedProps.trainer}</span>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <span style={{ fontWeight: 'bold' }}>User:</span>
                        <span>{eventInfo.event.extendedProps.user}</span>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <span style={{ fontWeight: 'bold' }}>Area:</span>
                        <span>{eventInfo.event.extendedProps.area}</span>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <span style={{ fontWeight: 'bold' }}>Booking Type:</span>
                        <span>{eventInfo.event.title}</span>
                      </div>
                    </div>
                  )
                } else if (eventInfo.event.title === 'Area') {
                  return (
                    <div>
                      <b>{eventInfo.timeText}</b>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <span style={{ fontWeight: 'bold' }}>Area:</span>
                        <span>{eventInfo.event.extendedProps.area}</span>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <span style={{ fontWeight: 'bold' }}>User:</span>
                        <span>{eventInfo.event.extendedProps.user}</span>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <span style={{ fontWeight: 'bold' }}>Booking Type:</span>
                        <span>{eventInfo.event.title}</span>
                      </div>
                    </div>
                  )
                }
              }}
            />
          </div>
        </div>
      </div>
      {showModal && (
        <AddBookingModal
          trainer={selectedSlot?.trainer || null}
          area={selectedSlot?.area || null}
          start_time={selectedSlot?.startTime || ''}
          end_time={selectedSlot?.endTime || ''}
          date={selectedSlot?.date || ''}
          show={showModal}
          onHide={handleModalClose}
          bookingId={selectedSlot?.id || ''}
          username={selectedSlot?.username || ''}
        />
      )}
    </div>
  )
}

export default NewBookingCalendar
