import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import MembershipRequestList from './components/MembershipRequestList'

const membershipBreadcrumbs: Array<PageLink> = [
  {
    title: 'Membership Requests',
    path: '/membership',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MembershipPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list/*'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Membership Requests'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={membershipBreadcrumbs}>List</PageTitle>
              <MembershipRequestList />
            </>
          }
        />
        <Route index element={<Navigate to='/membership/list' />} />
      </Route>
    </Routes>
  )
}

export default MembershipPage
