import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import {
  fetchAreaTypesList,
  fetchBranchList,
  fetchMembershipPackageList,
  fetchUsersList,
} from '../../../utils/SelectListMethods'
import { hoursOptions, reservationTimeIntervalOptions } from '../../../utils/Constants'
import { useAuth } from '../../auth/core/AuthProvider'

interface MembershipRequestEditModalProps {
  itemIdForUpdate: string
  show: boolean
  onClose: () => void
  refresh: Function
}

class MembershipRequest {
  user?: string
  membershipPackage?: string
  status?: string
}

const MembershipRequestEditModal: React.FC<MembershipRequestEditModalProps> = ({
  itemIdForUpdate,
  show,
  onClose,
  refresh,
}) => {
  const [formData, setFormData] = useState<MembershipRequest>({
    user: '',
    membershipPackage: '',
    status: '',
  })

  const [usersOptions, setUsersOptions] = useState<any[]>([])
  const [membershipPackageOptions, setMembershipPackageOptions] = useState<any[]>([])
  const { token } = useAuth()
  const getUsersOptions = async () => {
    fetchUsersList(token ?? '')
      .then((response) => {
        setUsersOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching user options:', error)
      })
  }

  const getMembershipPackageOptions = async () => {
    fetchMembershipPackageList(token ?? '')
      .then((response) => {
        setMembershipPackageOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching membership package options:', error)
      })
  }

  useEffect(() => {
    getUsersOptions()
    getMembershipPackageOptions()
  }, [])

  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'add') {
      axios
        .get(`/admin/membership_package_requests/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        })
        .then((response) => {
          setFormData({
            user: response.data.user['@id'] || '',
            membershipPackage: response.data.membershipPackage['@id'] || '',
            status: response.data.status,
          })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        user: '',
        membershipPackage: '',
        status: '',
      })
    }
  }

  const updateData = async () => {
    const updatedData = {
      ...formData,
    }

    axios
      .patch(`/admin/membership_package_requests/${itemIdForUpdate}`, updatedData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
      })
      .catch((error) => {
        console.error('Error updating data:', error)
      })
  }

  useEffect(() => {
    if (itemIdForUpdate !== 'add') {
      fetchSingleData()
    }
  }, [itemIdForUpdate])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    updateData()
  }

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{'Edit Membership Request'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='formUser'>
            <Form.Label>User</Form.Label>
            <Form.Control
              as='select'
              name='user'
              value={formData.user}
              onChange={handleChange}
              required
              disabled={formData.user !== ''}
            >
              <option value='' disabled>
                Select User
              </option>
              <option value=''>Select User</option>
              {usersOptions.map((user) => (
                <option key={user.value} value={user.value}>
                  {user.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='formMembershipPackage'>
            <Form.Label>Membership Package</Form.Label>
            <Form.Control
              as='select'
              name='membershipPackage'
              value={formData.membershipPackage}
              onChange={handleChange}
              required
              disabled={formData.membershipPackage !== ''}
            >
              <option value='' disabled>
                Select Membership Package
              </option>
              {membershipPackageOptions.map((membershipPackage) => (
                <option key={membershipPackage.value} value={membershipPackage.value}>
                  {membershipPackage.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='formStatus'>
            <Form.Label>Status</Form.Label>
            <Form.Control
              as='select'
              name='status'
              value={formData.status}
              onChange={handleChange}
              required
              disabled={formData.status === 'Canceled' || formData.status === 'Approved'}
            >
              <option value='' disabled>
                Select Status
              </option>
              <option value='Pending'>Pending</option>
              <option value='Approved'>Approved</option>
              <option value='Canceled'>Canceled</option>
            </Form.Control>
          </Form.Group>
          <Modal.Footer>
            <Button variant='secondary' onClick={onClose}>
              Close
            </Button>
            <Button variant='primary' type='submit'>
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default MembershipRequestEditModal
