import React from 'react'
import ResultViewer from '../../utils/ResultViewer'
import { KTSVG } from '../../../_metronic/helpers'
import Filters from '../../utils/ResultViewer/Filters'

class UsersResultViewer<T extends Record<string, any>> extends ResultViewer<T> {
  getBadgeClass = (status: string) => {
    switch (status) {
      case 'Active':
        return 'badge-success'
      case 'Blocked':
        return 'badge-warning'
      case 'Banned':
        return 'badge-danger'
      default:
        return ''
    }
  }

  render() {
    const { title, editCallback, deleteCallback, actionItems, header } = this.props
    const { data, loading, error, totalCount } = this.state

    return (
      <div className='card custom-card'>
        {/* Custom Header */}
        <div className='card-header border-0 pt-5 custom-header'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{totalCount} results found.</span>
          </h3>
          <div className='card-toolbar'>
            {/* Custom Menu */}
            <div className='card-toolbar custom-toolbar'>
              {actionItems?.map((item, index) => (
                <button
                  key={index}
                  className={item.class}
                  onClick={() => editCallback('new', this.fetchData)}
                >
                  <i className={item.icon} style={{ marginRight: '5px' }}></i>
                  {item.text}
                </button>
              ))}

              {/* Filters */}
              <div className='card-body'>
                <Filters
                  search={this.props.filter || []}
                  setFilterParams={this.handleFilterChange}
                  applyFilter={this.fetchData}
                  clearFilters={() => this.setState({ filters: {} })}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Custom Body */}
        <div className='card-body py-3 custom-body'>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : data && data.length > 0 ? (
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  {header && header.length > 0 ? (
                    <tr className='fw-bold text-muted bg-light'>
                      {header.map((item, index) => (
                        <th key={index} className={item.className}>
                          <div
                            onClick={() => this.handleSort(item.key)}
                            className={item.sort ? 'sortable-header' : ''}
                            style={{
                              cursor: item.sort ? 'pointer' : 'default',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }}
                          >
                            {item.title}
                            {this.state.sortKey === item.key ? (
                              <span>{this.state.sortOrder === 'asc' ? '↑' : '↓'}</span>
                            ) : (
                              item.sort && <span>⇅</span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ) : (
                    <>Header Error</>
                  )}
                </thead>
                <tbody>
                  {data.map((item: any, index: number) => (
                    <tr key={index}>
                      {header.map((headerItem, headerIndex) => (
                        <td key={headerIndex}>
                          {headerItem.key === 'actions' ? (
                            <div>
                              <a
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={() => editCallback(item['id'], this.fetchData)}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='svg-icon-3'
                                />
                              </a>
                              {deleteCallback && (
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                  onClick={() => deleteCallback(item['id'], this.fetchData)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </a>
                              )}
                            </div>
                          ) : headerItem.key === 'roles' ? (
                            Array.isArray(item[headerItem.key]) ? (
                              item[headerItem.key].map((role: any, index: number) => (
                                <span key={index} className='badge badge-light me-2'>
                                  {role === 'ROLE_ADMIN'
                                    ? 'Admin'
                                    : role === 'ROLE_USER'
                                    ? 'User'
                                    : role === 'ROLE_SUPER_ADMIN'
                                    ? 'Super Admin'
                                    : role === 'ROLE_COACH'
                                    ? 'Coach'
                                    : role === 'ROLE_PHYSIO'
                                    ? 'Physio'
                                    : role === 'ROLE_TRAINER'
                                    ? 'Trainer'
                                    : role === 'ROLE_PERSONAL_TRAINER'
                                    ? 'Personal Trainer'
                                    : role}
                                </span>
                              ))
                            ) : (
                              'N/A'
                            )
                          ) : headerItem.type === 'object' && headerItem.subKey ? (
                            headerItem.postProcess ? (
                              headerItem.postProcess(
                                item?.[headerItem.key]?.[headerItem.subKey] || 'N/A'
                              )
                            ) : (
                              item?.[headerItem.key]?.[headerItem.subKey] || 'N/A'
                            )
                          ) : headerItem.postProcess ? (
                            headerItem.postProcess(item?.[headerItem.key] || 'N/A')
                          ) : (
                            item?.[headerItem.key] || 'N/A'
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available.</p>
          )}
        </div>

        {/* Pagination */}
        <div className='card-footer d-flex justify-content-between align-items-center'>
          {this.renderPagination()}
        </div>
      </div>
    )
  }
}

export default UsersResultViewer
