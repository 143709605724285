import React, { SyntheticEvent, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import AreaTypeEditModal from './AreaTypeEditModal'
import ResultViewer from '../../../utils/ResultViewer'
import MembershipPackageEditModal from './MembershipPackageEditModal'

function MembershipPackageList() {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  let membershipPackageHeader = [
    {
      index: 1,
      type: 'field',
      key: 'id',
      title: 'ID',
      className: 'min-w-125px',
    },
    { index: 2, type: 'field', key: 'name', title: 'Name', className: 'min-w-125px' },
    { index: 3, type: 'field', key: 'price', title: 'Price', className: 'min-w-125px' },
    { index: 4, type: 'field', key: 'actions', title: 'Actions', className: 'min-w-125px' },
  ]

  return (
    <>
      <ResultViewer
        title={'Membership Packages'}
        dataUrl={'/admin/membership_packages'}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          if (id !== undefined && id !== '') {
            setItemIdForUpdate(id)
          }
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this membership package?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              let nid = id.split('/').pop()
              axios.delete('admin/membership_packages/' + nid).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[
          {
            text: 'Add New Membership Package',
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-golf-ball',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForUpdate !== undefined) {
                setItemIdForUpdate('add')
              }
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        header={membershipPackageHeader}
      />
      {itemIdForUpdate !== '' ? (
        <MembershipPackageEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onClose={() => {
            setItemIdForUpdate('')
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default MembershipPackageList
