import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { hoursOptions } from '../../../utils/Constants'
import { useAuth } from '../../auth/core/AuthProvider'
import { fetchTrainers } from '../../../utils/SelectListMethods'

interface AddTrainerAvailabilityModalProps {
  show: boolean
  onClose: () => void
  refresh: Function
  selectedDates: { start: string; end: string } | null
}

const TrainerAvailabilityAddModal: React.FC<AddTrainerAvailabilityModalProps> = ({
  show,
  onClose,
  refresh,
  selectedDates,
}) => {
  const [formData, setFormData] = useState({
    user: '',
    startsAt: '',
    endsAt: '',
    startTime: '',
    endTime: '',
    days: [] as string[],
  })
  const { hasValidRole, token } = useAuth()
  let apiUrl = 'trainer/availability_calendars'
  if (hasValidRole === 1) {
    apiUrl = 'admin/availability_calendars'
  }
  const [daysSelected, setDaysSelected] = useState<string[]>([])
  const [allSelected, setAllSelected] = useState(false)
  const [trainerOptions, setTrainersOptions] = useState<any[]>([])

  useEffect(() => {
    fetchTrainers(token ?? '').then((response) => {
      setTrainersOptions(response)
    })
  }, [])
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleDayClick = (index: string) => {
    setDaysSelected((prevDays) =>
      prevDays.includes(index) ? prevDays.filter((day) => day !== index) : [...prevDays, index]
    )
  }

  const handleAllClick = () => {
    if (allSelected) {
      setDaysSelected([])
    } else {
      setDaysSelected(['0', '1', '2', '3', '4', '5', '6'])
    }
    setAllSelected(!allSelected)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    createData()
  }

  const createData = async () => {
    const currentDate = new Date().toISOString().split('T')[0]

    if (!formData.startTime || !formData.endTime) {
      console.error('Start time and end time must be selected.')
      return
    }

    const editedStartTime = new Date(`${currentDate}T${formData.startTime}:00Z`).toISOString()
    const editedEndTime = new Date(`${currentDate}T${formData.endTime}:00Z`).toISOString()

    const newData = {
      ...formData,
      days: daysSelected,
      startTime: editedStartTime,
      endTime: editedEndTime,
    }

    try {
      await axios.post(apiUrl, newData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      onClose()
      refresh(true)
      setFormData({
        user: '',
        startsAt: '',
        endsAt: '',
        startTime: '',
        endTime: '',
        days: [],
      })
    } catch (error) {
      console.error('Error creating data:', error)
    }
  }

  useEffect(() => {
    setAllSelected(daysSelected.length === 7)
  }, [daysSelected])

  useEffect(() => {
    if (selectedDates) {
      setFormData((prevData) => ({
        ...prevData,
        startsAt: selectedDates.start,
        endsAt: selectedDates.end,
      }))
    }
  }, [selectedDates])

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Add Availability</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {hasValidRole === 1 ? (
            <Form.Group controlId='formUser'>
              <Form.Label>Trainer</Form.Label>
              <Form.Select name='user' value={formData.user} onChange={handleChange} required>
                <option value=''>Select Trainer</option>
                {trainerOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          ) : null}
          <Form.Group controlId='formStartsAt'>
            <Form.Label>Starts At</Form.Label>
            <Form.Control
              type='date'
              name='startsAt'
              value={formData.startsAt}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formEndsAt'>
            <Form.Label>Ends At</Form.Label>
            <Form.Control
              type='date'
              name='endsAt'
              value={formData.endsAt}
              onChange={handleChange}
              required
            />
          </Form.Group>

          {/* Days of Week Buttons */}
          <Form.Group controlId='formDays'>
            <Form.Label>Select Days</Form.Label>
            <div className='mb-3'>
              <Button
                variant={allSelected ? 'danger' : 'outline-primary'}
                onClick={handleAllClick}
                className='me-2 mb-2'
              >
                {allSelected ? 'Clear All' : 'All'}
              </Button>
              {daysOfWeek.map((day, index) => (
                <Button
                  key={index}
                  variant={daysSelected.includes(index.toString()) ? 'primary' : 'outline-primary'}
                  onClick={() => handleDayClick(index.toString())}
                  className='me-2 mb-2'
                >
                  {day}
                </Button>
              ))}
            </div>
          </Form.Group>

          <Form.Group controlId='formStartTime'>
            <Form.Label>Start Time</Form.Label>
            <Form.Select
              name='startTime'
              value={formData.startTime}
              onChange={handleChange}
              required
            >
              {hoursOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formEndTime'>
            <Form.Label>End Time</Form.Label>
            <Form.Select name='endTime' value={formData.endTime} onChange={handleChange} required>
              {hoursOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <div className='text-center'>
            <Button onClick={onClose} variant='light'>
              Cancel
            </Button>
            <Button type='submit' variant='primary'>
              Create
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default TrainerAvailabilityAddModal
