/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { User } from '../../../../../app/modules/auth/core/_models'
import { meApi } from '../../../../../app/modules/auth/core/_requests'
import { useAuth } from '../../../../../app/modules/auth/core/AuthProvider'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { token, hasValidRole, loggedIn } = useAuth()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const checkAuth = async () => {
      if (!token) {
        setLoading(false)
        return
      }
      try {
        await meApi()
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    checkAuth()
  }, [token])
  if (loggedIn && hasValidRole === 2) {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard/trainer'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItemWithSub
          title='Booking'
          fontIcon='bi-building'
          icon='/media/icons/duotune/general/gen014.svg'
          to={'/scheduler'}
        >
          <SidebarMenuItem
            to='/bookings/requests'
            title='Bookings'
            fontIcon='bi-building'
            icon='/media/icons/duotune/general/gen013.svg'
          />
          <SidebarMenuItem
            to='/bookings/calendar'
            title='Booking Calendar'
            fontIcon='bi-building'
            icon='/media/icons/duotune/general/gen066.svg'
          />
          <SidebarMenuItem
            to='/bookings/trainer-availability-calendars'
            title='Availability List View'
            fontIcon='bi-building'
            icon='/media/icons/duotune/general/gen059.svg'
          />
          <SidebarMenuItem
            to='/bookings/personal-available-calendar'
            title='Availability Calendar'
            fontIcon='bi-building'
            icon='/media/icons/duotune/general/gen014.svg'
          />
        </SidebarMenuItemWithSub>
      </>
    )
  } else if (loggedIn && hasValidRole === 1) {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />

        <SidebarMenuItem
          to='/users/list'
          title='Users'
          fontIcon='bi-user'
          icon='/media/icons/duotune/general/gen067.svg'
        />
        {/**
        <SidebarMenuItemWithSub
          title='Membership'
          fontIcon='bi-building'
          icon='/media/icons/duotune/general/gen029.svg'
          to={'/membership'}
        >
          <SidebarMenuItem
            to='/membership/list'
            title='Membership Requests'
            fontIcon='bi-building'
            icon='/media/icons/duotune/general/gen013.svg'
          />
        </SidebarMenuItemWithSub>
        */}
        <SidebarMenuItem
          to='/branches/list'
          title='Branches'
          fontIcon='bi-building'
          icon='/media/icons/duotune/general/gen018.svg'
        />
        <SidebarMenuItem
          to='/areas/list'
          title='Areas'
          fontIcon='bi-building'
          icon='/media/icons/duotune/general/gen025.svg'
        />
        {/**
            <SidebarMenuItem
        to='/lanes/list'
        title='Bays'
        fontIcon='bi-building'
        icon='/media/icons/duotune/general/gen059.svg'
      />
      <SidebarMenuItem
        to='/places/list'
        title='Places'
        fontIcon='bi-building'
        icon='/media/icons/duotune/general/gen017.svg'
      />
      
      */}

        <SidebarMenuItemWithSub
          title='Booking'
          fontIcon='bi-building'
          icon='/media/icons/duotune/general/gen014.svg'
          to={'/scheduler'}
        >
          <SidebarMenuItem
            to='/bookings/requests'
            title='Bookings'
            fontIcon='bi-building'
            icon='/media/icons/duotune/general/gen013.svg'
          />
          <SidebarMenuItem
            to='/bookings/calendar'
            title='Booking Calendar'
            fontIcon='bi-building'
            icon='/media/icons/duotune/general/gen066.svg'
          />
          <SidebarMenuItem
            to='/bookings/trainer-availability-calendars'
            title='Availability List View'
            fontIcon='bi-building'
            icon='/media/icons/duotune/general/gen059.svg'
          />
          <SidebarMenuItem
            to='/bookings/personal-available-calendar'
            title='Availability Calendar'
            fontIcon='bi-building'
            icon='/media/icons/duotune/general/gen014.svg'
          />
        </SidebarMenuItemWithSub>

        {/**
        <SidebarMenuItem
          to='/scheduler/all-view'
          title='All View'
          fontIcon='bi-building'
          icon='/media/icons/duotune/general/gen014.svg'
        />
        <SidebarMenuItem
          to='/scheduler/week-view'
          title='My Schedule'
          fontIcon='bi-building'
          icon='/media/icons/duotune/general/gen014.svg'
        />
        <SidebarMenuItem
          to='/scheduler/booking-view'
          title='Booking Calendar'
          fontIcon='bi-building'
          icon='/media/icons/duotune/general/gen014.svg'
        />
        */}
        {/**
      <SidebarMenuItemWithSub
        title='Clubs'
        fontIcon='bi-building'
        icon='/media/icons/duotune/general/gen056.svg'
        to={'/clubs'}
      >
        <SidebarMenuItem
          to='/clubs/#'
          title='Clubs List'
          fontIcon='bi-building'
          icon='/media/icons/duotune/general/gen056.svg'
        />
        <SidebarMenuItem
          to='/under-construction/clubs-users'
          title='Clubs Users'
          fontIcon='bi-building'
          icon='/media/icons/duotune/general/gen049.svg'
        />
      </SidebarMenuItemWithSub>
      */}
        <SidebarMenuItemWithSub
          title='Settings'
          fontIcon='bi-building'
          icon='/media/icons/duotune/coding/cod001.svg'
          to={'/settings'}
        >
          <SidebarMenuItem
            to='settings/area_types'
            title='Area Types'
            fontIcon='bi-building'
            icon='/media/icons/duotune/general/gen056.svg'
          />
          <SidebarMenuItem
            to='settings/membership_packages'
            title='Membership Packages'
            fontIcon='bi-building'
            icon='/media/icons/duotune/general/gen056.svg'
          />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem
          to='contact-forms/list'
          title='Contact Forms'
          fontIcon='bi-building'
          icon='/media/icons/duotune/communication/com011.svg'
        />
      </>
    )
  } else {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
      </>
    )
  }
}

export { SidebarMenuMain }
