import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

interface AreaEditModalProps {
  itemIdForUpdate: string
  show: boolean
  onClose: () => void
  refresh: Function
}

class AreaType {
  name?: string
}

const AreaTypeEditModal: React.FC<AreaEditModalProps> = ({
  itemIdForUpdate,
  show,
  onClose,
  refresh,
}) => {
  const [formData, setFormData] = useState<AreaType>({
    name: '',
  })
  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'add') {
      axios
        .get(`/admin/area_types/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        })
        .then((response) => {
          setFormData({
            name: response.data.name,
          })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        name: '',
      })
    }
  }

  const updateData = async () => {
    axios
      .patch(`/admin/area_types/${itemIdForUpdate}`, formData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
      })
      .catch((error) => {
        console.error('Error updating data:', error)
      })
  }

  const createData = async () => {
    axios
      .post(`/admin/area_types`, formData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
        setFormData({
          name: '',
        })
      })
      .catch((error) => {
        console.error('Error creating data:', error)
      })
  }

  useEffect(() => {
    fetchSingleData()
  }, [itemIdForUpdate])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'add') {
      createData()
    } else {
      updateData()
    }
  }

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{itemIdForUpdate === 'add' ? 'Add Area Type' : 'Edit Area Type'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='formUserTitle'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          {/* Add more form fields */}
          <div className='text-end mt-3'>
            <Button variant='secondary' onClick={onClose} className='me-2'>
              Cancel
            </Button>
            <Button type='submit' variant='primary'>
              Save changes
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default AreaTypeEditModal
