import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import axios from 'axios'
import { AuthContextType, LoginParams, User } from './_models'
import { loginPostApi, meApi } from './_requests'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'

const authProvider = createContext<AuthContextType>({} as unknown as AuthContextType)

const { Provider } = authProvider

const useAuthProvider = (): [boolean, AuthContextType] => {
  const [initialized, setInitialized] = useState(false)

  const [user, setUser] = useState()
  const [token, setToken] = useState<string | undefined>(
    localStorage.getItem('@auth/token') ?? undefined
  )
  const [hasValidRole, setHasValidRole] = useState(0)
  const [username, setUsername] = useState<string | undefined>()
  const [userId, setUserId] = useState<string>('')
  const checkAuth = useCallback(async () => {
    if (token == null) {
      setToken(undefined)
      return
    }

    try {
      const meResponse = await meApi(token)
      if (!meResponse) {
        localStorage.removeItem('@auth/token')
        setToken(undefined)
        return
      } else {
        setUserId(meResponse.id)
        const roles = Array.isArray(meResponse.roles)
          ? meResponse.roles
          : Object.values(meResponse.roles)

        if (
          Array.isArray(roles) &&
          (roles.includes('ROLE_SUPER_ADMIN') || roles.includes('ROLE_ADMIN'))
        ) {
          setHasValidRole(1)
        } else if (
          Array.isArray(roles) &&
          !roles.includes('ROLE_SUPER_ADMIN') &&
          !roles.includes('ROLE_ADMIN') &&
          (roles.includes('ROLE_PERSONAL_TRAINER') ||
            roles.includes('ROLE_PHYSIO') ||
            roles.includes('ROLE_COACH'))
        ) {
          setHasValidRole(2)
        }
      }
      setUsername(meResponse.name)
      setUser(meResponse.email)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.removeItem('@auth/token')
        setToken(undefined)
      } else {
        console.error('An error occurred:', error)
      }
    }
  }, [token])

  const logout = useCallback(() => {
    setToken(undefined)
  }, [])

  const login = useCallback(
    (loginParams: LoginParams) =>
      loginPostApi(loginParams)
        .then((loginResponse) => setToken(loginResponse.token))
        .then(() => true),
    []
  )

  useEffect(() => {
    checkAuth().then(() => setInitialized(true))

    if (!token) {
      setUser(undefined)
      setHasValidRole(0)
      setUsername(undefined)
      localStorage.removeItem('@auth/token')
      axios.defaults.headers.common = {}
      return
    }

    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    }

    localStorage.setItem('@auth/token', token)
  }, [logout, checkAuth, token])
  return [
    initialized,
    {
      login,
      logout,
      user,
      username,
      token,
      loggedIn: user != null,
      hasValidRole,
      userId,
    },
  ]
}

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [initialized, auth] = useAuthProvider()
  return <Provider value={auth}>{initialized ? children : <LayoutSplashScreen />}</Provider>
}

export const useAuth = () => useContext(authProvider)
