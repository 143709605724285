import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

interface MembershipPackageEditModalProps {
  itemIdForUpdate: string
  show: boolean
  onClose: () => void
  refresh: Function
}

class MembershipPackage {
  name?: string
  price?: number | null
}

const MembershipPackageEditModal: React.FC<MembershipPackageEditModalProps> = ({
  itemIdForUpdate,
  show,
  onClose,
  refresh,
}) => {
  const [formData, setFormData] = useState<MembershipPackage>({
    name: '',
    price: null,
  })
  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'add') {
      axios
        .get(`/admin/membership_packages/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        })
        .then((response) => {
          setFormData({
            name: response.data.name,
            price: Number(response.data.price),
          })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        name: '',
        price: null,
      })
    }
  }

  const updateData = async () => {
    axios
      .patch(`/admin/membership_packages/${itemIdForUpdate}`, formData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
      })
      .catch((error) => {
        console.error('Error updating data:', error)
      })
  }

  const createData = async () => {
    axios
      .post(`/admin/membership_packages`, formData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
        setFormData({
          name: '',
          price: null,
        })
      })
      .catch((error) => {
        console.error('Error creating data:', error)
      })
  }

  useEffect(() => {
    fetchSingleData()
  }, [itemIdForUpdate])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'price') {
      setFormData((prevData) => ({ ...prevData, [name]: Number(value) }))
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }))
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'add') {
      createData()
    } else {
      updateData()
    }
  }

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>
          {itemIdForUpdate === 'add' ? 'Add Membership Package' : 'Edit Membership Package'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='formUserTitle'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formUserPrice'>
            <Form.Label>Price</Form.Label>
            <Form.Control
              type='text'
              name='price'
              value={
                formData.price !== null && formData.price !== undefined
                  ? formData.price.toString()
                  : ''
              }
              onChange={handleChange}
              required
            />
          </Form.Group>
          {/* Add more form fields */}
          <div className='text-end mt-3'>
            <Button variant='secondary' onClick={onClose} className='me-2'>
              Cancel
            </Button>
            <Button type='submit' variant='primary'>
              Save changes
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default MembershipPackageEditModal
