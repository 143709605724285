import { Form } from 'react-bootstrap'
import Element, { BaseProps } from './Element'
import { ChangeEventHandler } from 'react'

type SelectProps = BaseProps & {
  name: string
  options: {
    value: string
    label: string
  }[]
  multiple?: boolean
  defaultValue: string | number | readonly string[]
  onChange: ChangeEventHandler
  placeholder?: string
}

export default function Select(props: SelectProps) {
  return (
    <Element title={props.title} required={props.required}>
      <Form.Select name={props.name} value={props.defaultValue ?? ''} onChange={props.onChange}>
        <option value={''}>{props.placeholder ?? 'Select ' + props.title}</option>
        {props.options.map((option) => (
          <option key={`option_${option.value}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Element>
  )
}
