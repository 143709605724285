import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { forgetPasswordPostAPI, requestResetPasswordPostAPI } from '../core/_requests'
import OTPModal from './OTPModal'
import '../login.css'
import ResetPasswordModal from './ResetPasswordModal'
import Swal from 'sweetalert2'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [showOTPModal, setShowOTPModal] = useState(false)
  const [resendStatus, setResendStatus] = useState(false)
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false) // Yeni state
  const [reset_token, setResetToken] = useState('')
  const closeResetModal = () => {
    setShowResetPasswordModal(false)
  }
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setHasErrors(undefined)
      setTimeout(async () => {
        await forgetPasswordPostAPI(values.email).then((res) => {
          if (res && res.status === 'success') {
            setHasErrors(false)
            setShowOTPModal(true)
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res?.message || 'Something went wrong!',
              confirmButtonText: 'OK',
            })
            setHasErrors(true)
            setSubmitting(false)
          }
        })
      }, 1000)
    },
  })

  const closeModal = () => {
    setShowOTPModal(false)
  }

  const handleOTPSubmit = (otpCode: string) => {
    requestResetPasswordPostAPI({ code: otpCode, email: formik.values.email })
      .then((res) => {
        if (res.data && res.data.status !== 'error') {
          setShowOTPModal(false)
          setResetToken(res.reset_token)
          setShowResetPasswordModal(true)
        } else {
          setHasErrors(true)
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const handleResendCode = async () => {
    setResendStatus(false)
    await forgetPasswordPostAPI(formik.values.email).then((res) => {
      if (res && res.status === 'success') {
        setResendStatus(true)
      } else {
        setResendStatus(false)
      }
    })
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Enter your email to reset your password.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && showOTPModal === true && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Verify OTP. Please check your email</div>
            <OTPModal
              show={true}
              resendStatus={resendStatus}
              handleClose={closeModal}
              handleSubmit={handleOTPSubmit}
              handleResend={handleResendCode}
            />
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='kt_password_reset_submit' className='btn custom-btn me-4'>
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
      {showResetPasswordModal && (
        <ResetPasswordModal reset_token={reset_token} handleClose={closeResetModal} />
      )}
    </>
  )
}
