import React, { SyntheticEvent, useState } from 'react'
import ResultViewer from '../../utils/ResultViewer'
import Swal from 'sweetalert2'
import axios from 'axios'

function ContactFormsList() {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const filters = [
    {
      name: 'email',
      label: 'Email',
      type: 'text',
    },
  ]
  let contactFormHeader = [
    { index: 1, type: 'field', key: 'id', title: 'ID', className: 'min-w-125px' },
    { index: 2, type: 'field', key: 'email', title: 'Email', className: 'min-w-125px' },
    { index: 3, type: 'field', key: 'name', title: 'Name', className: 'min-w-125px' },
    { index: 4, type: 'field', key: 'phone', title: 'Phone', className: 'min-w-125px' },
    { index: 5, type: 'field', key: 'message', title: 'Message', className: 'min-w-125px' },
    { index: 6, type: 'field', key: 'actions', title: 'Actions', className: 'min-w-125px' },
  ]
  return (
    <>
      <ResultViewer
        title={'Contact Forms'}
        filter={filters}
        dataUrl={'/admin/contact_forms'}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForUpdate(id)
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this contact form?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete('admin/contact_forms/' + id).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[]}
        header={contactFormHeader}
      />
      {itemIdForUpdate !== '' ? <></> : <></>}
    </>
  )
}

export default ContactFormsList
